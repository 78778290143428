<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntInput from '@/components/AntInput.vue';
import { STAKEHOLDERS } from '@/modules/modules';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import NumberSelector from '@/components/NumberSelector.vue';
import { cloneDeep } from 'lodash';
import { compressAndConvertImageFileToBase64 } from '@/utils';

export default defineComponent({
  name: 'Stakeholders',
  components: {
    DeleteDialog,
    AntLoading,
    AntInput,
    PanelResizable,
    ModuleNavigationBar,
    NumberSelector,
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      numSelectorContainerWidth: null,
      numberSelectOffset: 3,

      stakeholderCreateName: null,
      stakeholderCreateMenu: false,
      stakeholderEdit: false,
      stakeholderRemark: false,
      stakeholderToDelete: null,
      stakeholderInfo: {},
      stakeholderInfoValid: null,
      selectedStakeholderFlag: null,

      remarkText: '',

      series: [],
      chartOptions: {},

      infoLabels: {
        name: 'Name',
        organisation: 'Organisation',
        role: 'Role',
        SBS: 'SBS',
        website: 'Website',
        contact_name: 'Contact name',
        contact_linkedIn: 'Contact linkedIn',
        contact_email: 'Contact email',
        contact_phone: 'Contact phone',
      },
    };
  },
  computed: {
    ...mapGetters(['project', 'sbsRecords']),
    ...mapGetters({
      status: 'stakeHolders/status',
      stakeholders: 'stakeHolders/stakeholders',
      selectedStakeholder: 'stakeHolders/selectedStakeholder',
      stakeholderRemarks: 'stakeHolders/stakeholderRemarks',
    }),
    showImg() {
      const img = this.stakeholderInfo.image_base64;
      return img && typeof img === 'string';
    },
    sortedStakeholders() {
      const stakeholders = cloneDeep(this.stakeholders);
      return stakeholders.sort((a, b) => a?.name.localeCompare(b?.name));
    },
  },
  watch: {
    stakeholders: {
      deep: true,
      handler(value) {
        if (value) {
          this.updateChartSeries();
          this.updateChartOptions();
        }
      },
    },
    selectedStakeholder: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.numSelectorContainerWidth =
              this.$refs.numSelectorContainer?.clientWidth;
          });
        }
        this.stakeholderInfo = value ? { ...value } : {};
        this.recalculateFullscreen();
      },
    },
    numSelectorContainerWidth: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value) return;
        const btnWidth = 64;
        const btnsWisibleWidth = btnWidth * 3;
        const widthToRenderButtons = value - btnsWisibleWidth;
        const offset = Math.floor(widthToRenderButtons / btnWidth / 2);
        if (offset && !isNaN(offset) && offset > 0) {
          this.numberSelectOffset = offset;
        } else {
          this.numberSelectOffset = 0;
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize);
    });

    this.$store.dispatch('stakeHolders/loadModuleData', {
      projectId: this.project.id,
      moduleId: this.project.modules.find(
        (module) => module.route === STAKEHOLDERS
      ).id,
      sessionId: this.$route.params.sessionId ?? null,
    });
    if (this.selectedStakeholder) {
      this.stakeholderInfo = this.selectedStakeholder;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (this.selectedStakeholder) {
        this.numSelectorContainerWidth =
          this.$refs.numSelectorContainer?.clientWidth || null;
      }
    },
    stringToConstantColor,
    updateChartSeries() {
      const createMatrix = (depth) => {
        return Array.from({ length: depth }, (_, rowIndex) =>
          Array.from(
            { length: depth },
            (_, colIndex) => rowIndex * depth + colIndex
          )
        );
      };

      const groupedItems = this.stakeholders.reduce((groups, item) => {
        const key = `${item.interest}-${item.influence}`;
        if (!groups[key]) groups[key] = [];
        groups[key].push(item);
        return groups;
      }, {});
      const groupedItemsWithoutDuplicates = Object.fromEntries(
        Object.entries(groupedItems).filter(([key, value]) => value.length > 1)
      );

      this.series = this.stakeholders.map((item, i) => {
        const key = `${item.interest}-${item.influence}`;
        let x = item.interest;
        let y = item.influence;

        if (groupedItemsWithoutDuplicates[key]) {
          const duplicatedCoordItems = groupedItemsWithoutDuplicates[key];
          const matrixDepth = Math.ceil(Math.sqrt(duplicatedCoordItems.length));

          //Creates a matrix with indices from 0 to n.
          const matrix = createMatrix(matrixDepth);
          let kMin = -(matrixDepth - 1) / 2;
          let d = 0.15;

          //Creating an offset for elements with the same values.
          for (let i = 0; i < matrix.length; i++) {
            let ik = kMin * d + i * d;
            for (let j = 0; j < matrix[i].length; j++) {
              let ij = kMin * d + j * d;
              const list = duplicatedCoordItems;
              const index = matrix[i][j];
              const currentItem = list[index];
              if (currentItem?.id === item?.id) {
                x = item.interest + ik;
                y = item.influence + ij;
              }
            }
          }
        }

        return {
          name: item.name,
          data: [
            {
              x,
              y,
            },
          ],
          zIndex: 500,
        };
      });
    },
    updateChartOptions() {
      const optionsParams = this.stakeholders.reduce(
        (acc, item) => {
          acc.colors.push(stringToConstantColor(item.id));
          acc.markerSizes.push(item.image_base64 ? 15 : 15); //Can be modified to change the image size.
          acc.fillTypes.push(item.image_base64 ? 'image' : 'solid');
          acc.fillImages.push(
            item.image_base64 ? item.image_base64 : undefined
          );
          return acc;
        },
        {
          colors: [],
          markerSizes: [],
          fillTypes: [],
          fillImages: [],
        }
      );

      this.chartOptions = {
        legend: {
          show: false,
        },
        colors: optionsParams.colors,
        dataLabels: {
          enabled: false,
        },
        chart: {
          zoom: {
            enabled: false,
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.$store.dispatch(
                'stakeHolders/toggleStakeholder',
                this.stakeholders[config.seriesIndex]
              );
            },
          },
        },
        xaxis: {
          tickAmount: 10,
          min: 0,
          max: 10,
          title: {
            text: 'Interest',
          },
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        yaxis: {
          min: 0,
          max: 10,
          tickAmount: 10,
          title: {
            text: 'Influence',
          },
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        grid: {
          show: true,
          position: 'back',
        },
        annotations: {
          xaxis: [
            {
              x: 5,
              strokeDashArray: 0,
              borderColor: '#adadad',
            },
          ],
          yaxis: [
            {
              y: 5,
              strokeDashArray: 0,
              borderColor: '#adadad',
            },
          ],
        },
        markers: {
          size: optionsParams.markerSizes,
          strokeColors: '#fff',
          strokeWidth: 2,
          hover: {
            sizeOffset: 0,
          },
        },
        fill: {
          type: optionsParams.fillTypes,
          image: {
            src: optionsParams.fillImages,
            //Determines the image size (equal appr. 2n of the image size)
            width: 29.3,
            height: 29.9,
          },
        },
        zIndex: {
          markers: 500,
        },
        tooltip: {
          enabled: true,
          x: {
            show: false,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName, value) => {
                return seriesName;
              },
            },
          },
        },
      };
    },
    async createStakeholder() {
      await this.$store.dispatch(
        'stakeHolders/createStakeholder',
        this.stakeholderCreateName
      );
      this.stakeholderCreateName = null;
      this.stakeholderCreateMenu = false;
    },
    async cancelCreateStakeholder() {
      this.stakeholderCreateName = null;
      this.stakeholderCreateMenu = false;
    },
    async deleteStakeholder() {
      await this.$store.dispatch(
        'stakeHolders/deleteStakeholder',
        this.stakeholderToDelete.id
      );
      this.stakeholderToDelete = null;
    },
    updateStakeholderValue(column, value) {
      const body = {};
      body[column] = value;

      this.$store.dispatch('stakeHolders/updateStakeholder', body);
    },
    updateStakeholder(column, value) {
      let body = {};
      body[column] = this.selectedStakeholder[column] + value;

      this.$store.dispatch('stakeHolders/updateStakeholder', body);
    },
    async saveInfo() {
      if (
        JSON.stringify(this.stakeholderInfo) ===
        JSON.stringify(this.selectedStakeholder)
      ) {
        this.stakeholderEdit = false;
        return;
      }

      this.$refs.updateForm.validate();
      if (!this.stakeholderInfoValid) {
        this.$store.commit('showNotification', {
          content: 'Invalid data in form',
          color: 'error',
        });
        return;
      }

      let body;
      const { image_base64: image, ...info } = this.stakeholderInfo;
      body = info;
      if (image && typeof image === 'object') {
        const imageBase64 = await compressAndConvertImageFileToBase64(image);
        if (imageBase64) {
          body.image_base64 = imageBase64;
        }
      }
      if (image === null) body.image_base64 = '';

      this.$wait.start('module.stakeHolders.stakeholder.update');
      this.$store
        .dispatch('stakeHolders/updateStakeholder', body)
        .then(() => {
          this.stakeholderEdit = false;
        })
        .finally(() => {
          this.$wait.end('module.stakeHolders.stakeholder.update');
        });
    },
    sendRemark() {
      if (!this.remarkText) {
        this.clearRemark();
        return;
      }

      this.$wait.start('module.stakeHolders.stakeholder.sendRemark');
      this.$store
        .dispatch('stakeHolders/createStakeholderRemark', {
          stakeholder_id: this.selectedStakeholder.id,
          remark: this.remarkText,
        })
        .then(() => {
          this.clearRemark();
        })
        .finally(() => {
          this.$wait.end('module.stakeHolders.stakeholder.sendRemark');
        });
    },
    clearRemark() {
      this.stakeholderRemark = false;
      this.remarkText = '';
    },
    recalculateFullscreen() {
      if (this.selectedStakeholder) {
        this.selectedStakeholderFlag = !!this.selectedStakeholder?.id;
        return;
      }
      setTimeout(
        () => (this.selectedStakeholderFlag = !!this.selectedStakeholder?.id),
        250
      );
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="Stakeholders" />
    <div
      v-if="status === 'success'"
      ref="dataContainer"
      class="flex-grow-1 d-flex overflow-y-auto"
      @resize="handleResize"
    >
      <div
        class="d-flex flex-column flex-grow-1 overflow-hidden background-white ant-border-right"
        style="width: 250px"
      >
        <v-list subheader two-line>
          <v-subheader>
            Stakeholders
            <v-spacer />
            <v-menu
              v-model="stakeholderCreateMenu"
              :close-on-content-click="false"
            >
              <template #activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template #activator="{ on: tooltip }">
                    <v-icon
                      class="ant-icon"
                      dense
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>New stakeholder</span>
                </v-tooltip>
              </template>
              <v-card class="pa-2">
                <ant-input label="Name" top-margin="mt-0">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderCreateName"
                      autofocus
                      dense
                      filled
                      hide-details
                      placeholder="name"
                      single-line
                      @keydown.esc="cancelCreateStakeholder"
                      @keydown.enter="createStakeholder"
                    />
                  </template>
                </ant-input>
              </v-card>
            </v-menu>
            <delete-dialog
              :dialog="stakeholderToDelete !== null"
              :title="`Are you sure you want to delete ${stakeholderToDelete?.name}`"
              @closeDialog="stakeholderToDelete = null"
              @deleteAction="deleteStakeholder"
            />
          </v-subheader>

          <v-list-item-group
            :value="selectedStakeholder && selectedStakeholder.id"
            color="primary"
          >
            <v-list-item
              v-for="stakeholder in sortedStakeholders"
              :key="stakeholder.id"
              :value="stakeholder.id"
              class="stakeholder-item"
              @click="
                $store.dispatch('stakeHolders/toggleStakeholder', stakeholder)
              "
            >
              <v-list-item-icon
                class="d-flex align-center justify-center ma-0 mr-5"
              >
                <v-icon
                  v-if="!stakeholder.image_base64"
                  :color="stringToConstantColor(stakeholder.id)"
                  large
                  >mdi-checkbox-blank-circle
                </v-icon>
                <v-img
                  v-show="stakeholder.image_base64"
                  :src="stakeholder.image_base64"
                  height="36"
                  width="36"
                />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="self-start body-1"
                  >{{ stakeholder.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="self-start caption">
                  {{ stakeholder.organisation }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  class="stakeholder-delete-icon ant-icon"
                  icon
                  @click.stop="stakeholderToDelete = stakeholder"
                >
                  <v-icon dense>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="flex-grow-1 d-flex flex-column overflow-y-auto">
        <transition-group
          :class="{ fullscreen: !selectedStakeholderFlag }"
          class="sh-container pa-5 overflow-y-auto flex-grow-1"
          name="simple-fade"
          tag="div"
        >
          <div
            key="graph"
            class="background-white d-flex flex-column overflow-hidden sh-card"
          >
            <div class="grid-item-header">
              <span class="py-2">Stakeholder analyse</span>
            </div>
            <div class="flex-grow-1 d-flex align-center justify-center pa-2">
              <apexchart
                :options="chartOptions"
                :series="series"
                class="flex-grow-1"
                height="90%"
                type="scatter"
              />
            </div>
          </div>
          <div
            v-if="selectedStakeholder"
            key="info"
            class="ant-glass-background d-flex flex-column overflow-hidden sh-card"
          >
            <div class="grid-item-header">
              <span class="py-2">Info</span>
              <div
                class="pos-abs top-0 bottom-0 right-0 d-flex pr-2 justify-center align-center"
              >
                <v-btn
                  v-if="!stakeholderEdit"
                  class="d-block flex-grow-1"
                  elevation="0"
                  icon
                  small
                  type="button"
                  @click="stakeholderEdit = true"
                >
                  <v-tooltip left>
                    <template #activator="{ on: tooltip }">
                      <v-icon
                        class="ant-icon"
                        dense
                        small
                        v-on="{ ...tooltip }"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit info</span>
                  </v-tooltip>
                </v-btn>
                <v-btn
                  v-else
                  :loading="$wait.is('module.stakeHolders.stakeholder.update')"
                  class="d-block flex-grow-1"
                  color="primary"
                  elevation="0"
                  small
                  type="button"
                  @click="saveInfo"
                >
                  Save
                </v-btn>
              </div>
            </div>

            <div
              v-if="!stakeholderEdit"
              class="py-2 px-4 overflow-y-auto flex-grow-1 stakeholder-info"
            >
              <div v-if="showImg" class="d-flex justify-center align-end">
                <v-avatar class="mr-2" color="primary" size="40">
                  <v-img
                    v-if="stakeholderInfo.image_base64"
                    :src="stakeholderInfo.image_base64"
                  />
                </v-avatar>
              </div>
              <div v-for="key in Object.keys(infoLabels)" :key="key">
                <label>{{ infoLabels[key] }}:</label>
                <span>{{ stakeholderInfo[key] }}</span>
              </div>
            </div>
            <div
              v-if="stakeholderEdit"
              class="py-2 px-4 overflow-y-auto pb-4 flex-grow-1"
            >
              <v-form ref="updateForm" v-model="stakeholderInfoValid">
                <div>
                  <div v-if="showImg" class="d-flex justify-center align-end">
                    <v-avatar class="mr-2" color="primary" size="40">
                      <v-img
                        v-if="stakeholderInfo.image_base64"
                        :src="stakeholderInfo.image_base64"
                      />
                    </v-avatar>
                  </div>
                  <ant-input
                    class="full-width"
                    is-optional
                    label="Name"
                    top-margin="0"
                  >
                    <template #input-field>
                      <v-text-field
                        v-model="stakeholderInfo.name"
                        dense
                        filled
                        hide-details
                        placeholder="Name"
                        single-line
                      />
                    </template>
                  </ant-input>
                </div>

                <ant-input is-optional label="Image">
                  <template #input-field>
                    <v-file-input
                      v-model="stakeholderInfo.image_base64"
                      accept="image/png, image/jpeg, image/bmp"
                      dense
                      filled
                      hide-details
                      placeholder="Image"
                      prepend-icon="mdi-file-image-outline"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="Organisation">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.organisation"
                      dense
                      filled
                      hide-details
                      placeholder="Organisation"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="Role">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.role"
                      dense
                      filled
                      hide-details
                      placeholder="Role"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="SBS">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.SBS"
                      dense
                      filled
                      hide-details
                      placeholder="SBS"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="Website">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.website"
                      :rules="[rules.url]"
                      dense
                      filled
                      hide-details
                      placeholder="Website"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="Contact name">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.contact_name"
                      dense
                      filled
                      hide-details
                      placeholder="Contact name"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="Contact LinkedIn">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.contact_linkedIn"
                      dense
                      filled
                      hide-details
                      placeholder="Contact LinkedIn"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="Contact email">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.contact_email"
                      dense
                      filled
                      hide-details
                      placeholder="Contact email"
                      single-line
                    />
                  </template>
                </ant-input>
                <ant-input is-optional label="Contact phone">
                  <template #input-field>
                    <v-text-field
                      v-model="stakeholderInfo.contact_phone"
                      dense
                      filled
                      hide-details
                      placeholder="Contact phone"
                      single-line
                    />
                  </template>
                </ant-input>
              </v-form>
            </div>
          </div>
          <div
            v-if="selectedStakeholder"
            key="graph-controls"
            ref="numSelectorContainer"
            class="ant-glass-background d-flex flex-column overflow-hidden sh-card widget-controls"
          >
            <div class="grid-item-header">Controls</div>
            <div class="d-flex flex-column align-center pa-15">
              <v-slider
                :value="selectedStakeholder.interest"
                class="mb-15"
                label="Interest"
                max="10"
                min="0"
                step="0.1"
                style="width: 100%"
                thumb-color="primary"
                thumb-label="always"
                thumb-size="50"
                @change="updateStakeholderValue('interest', $event)"
              />
              <v-slider
                :value="selectedStakeholder.influence"
                label="Influence"
                max="10"
                min="0"
                step="0.1"
                style="width: 100%"
                thumb-color="primary"
                thumb-label="always"
                thumb-size="50"
                @change="updateStakeholderValue('influence', $event)"
              />
            </div>
          </div>
          <div
            v-if="selectedStakeholder"
            key="comments"
            class="ant-glass-background d-flex flex-column overflow-hidden sh-card widget-remarks"
          >
            <div class="grid-item-header">
              <v-progress-linear
                :active="$wait.is('module.stakeHolders.remarks.get')"
                :indeterminate="$wait.is('module.stakeHolders.remarks.get')"
                absolute
                bottom
                color="primary lighten-4"
              />
              <span class="py-2">Remarks</span>
              <div
                class="pos-abs top-0 bottom-0 right-0 d-flex pr-2 justify-center align-center"
              >
                <v-btn
                  v-if="!stakeholderRemark"
                  class="d-block flex-grow-1"
                  elevation="0"
                  icon
                  small
                  type="button"
                  @click="stakeholderRemark = true"
                >
                  <v-tooltip left>
                    <template #activator="{ on: tooltip }">
                      <v-icon class="ant-icon" dense v-on="{ ...tooltip }">
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>Create remark</span>
                  </v-tooltip>
                </v-btn>
                <v-btn
                  v-else
                  :disabled="
                    $wait.is('module.stakeHolders.stakeholder.sendRemark')
                  "
                  class="d-block flex-grow-1"
                  elevation="0"
                  icon
                  small
                  type="button"
                  @click="clearRemark"
                >
                  <v-tooltip left>
                    <template #activator="{ on: tooltip }">
                      <v-icon class="ant-icon" dense v-on="{ ...tooltip }">
                        mdi-close
                      </v-icon>
                    </template>
                    <span>Cancel</span>
                  </v-tooltip>
                </v-btn>
              </div>
            </div>
            <div
              v-if="stakeholderRemark"
              class="pa-2 d-flex flex-column align-end"
            >
              <v-textarea
                v-model="remarkText"
                :rules="[rules.required]"
                class="full-width"
                filled
                hide-details
                placeholder="Remark"
              />
              <v-btn
                :disabled="!remarkText"
                :loading="
                  $wait.is('module.stakeHolders.stakeholder.sendRemark')
                "
                class="d-block mt-2"
                color="primary"
                elevation="0"
                small
                type="button"
                @click="sendRemark"
              >
                {{ 'Send' }}
              </v-btn>
            </div>
            <div class="pa-2 d-flex flex-column overflow-y-auto">
              <div v-for="remark in stakeholderRemarks" :key="remark.id">
                <div class="remark-wrapper ant-panel--white pa-2 my-1 radius-4">
                  <div class="caption">
                    <span>{{ remark.user_name || 'Anonymous' }}</span>
                    <span v-if="remark.user_email">
                      &nbsp;-&nbsp;
                      <a :href="`mailto:${remark.user_email}`">{{
                        remark.user_email
                      }}</a>
                    </span>
                  </div>
                  <div class="mt-2">
                    {{ remark.remark }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <div v-else class="flex-grow-1 d-flex align-center justify-center">
      <ant-loading />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.grid-item-header {
  color: var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: 50px;
  background-color: white;
  position: relative;
  font-weight: bold;
  border-bottom: solid 1px lightgrey;
}

.apexcharts-marker-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sh-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;

  &.fullscreen {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }

  .sh-card {
    min-height: 250px;
  }

  @media (max-width: 1440px) {
    grid-template-rows: repeat(3, 1fr);
    .widget-controls {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
    }
    .widget-remarks {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
    }
    .sh-card {
      min-height: 350px;
    }
    grid-gap: 10px;
  }
  @media (max-width: 1356px) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    .widget-controls {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
    }
    .widget-remarks {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 4;
    }
    .sh-card {
      min-height: 400px;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.stakeholder-info {
  div + div {
    margin-top: 8px;
  }

  label {
    font-weight: 700;
    display: inline-block;
    min-width: 150px;
    text-align: right;
    color: #565656;
    margin-right: 0.5rem;
  }
}

.stakeholder-item {
  align-items: center;

  .stakeholder-delete-icon {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .stakeholder-delete-icon {
      opacity: 1;
    }
  }

  .v-list-item__icon {
    align-self: unset;
  }

  .v-list-item__title {
    align-self: start;
  }
}
</style>
