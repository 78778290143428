<template>
  <div class="d-flex align-center flex-grow-1 justify-end" style="z-index: 30">
    <v-btn
      :disabled="value <= 1"
      color="white"
      elevation="0"
      tile
      @click="onChange(value - 1)"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      v-for="(val, index) in range"
      :key="`${val}-${index}`"
      :disabled="value === val"
      color="white"
      elevation="0"
      tile
      @click="onChange(val)"
    >
      <span>{{ val }}</span>
    </v-btn>
    <v-btn
      :disabled="value >= max"
      color="white"
      elevation="0"
      tile
      @click="onChange(value + 1)"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NumberSelector',
  props: {
    diff: {
      type: [Number],
      default: 3,
    },
    max: {
      type: [Number],
      default: 10,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    range() {
      const size = this.diff * 2 + 1;
      const min =
        Math.min(this.max - this.diff, Math.max(this.value, this.diff + 1)) -
        this.diff;
      return Array(size)
        .fill(0)
        .map((v, index) => min + index)
        .filter((v) => v >= 1 && v <= this.max);
    },
  },
  methods: {
    onChange(number) {
      this.$emit('on-change', number);
    },
  },
};
</script>

<style lang="scss" scoped></style>
